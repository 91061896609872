import React from 'react';
import './styles/LoadingControls.css';

interface LoadingControlsProps {
  className?: string;
}

export default function LoadingControls({ className }: LoadingControlsProps) {
  return (
    <div className={`loading-control-container ${className || ''}`}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
}
