import './diagram-line.component.css';
import { useRef } from 'react';
import AddPlus from '../add-plus/add-plus.component';

export enum DiagramLineDirection {
  LeftToRight,
  RightToLeft,
}

export interface DiagramLineSettings {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface DiagramLineProps {
  userHasPermissions: boolean;
  startItem: HTMLElement;
  controlContainer: HTMLElement;
  endItem: HTMLElement;
  direction?: DiagramLineDirection;
  onClick: () => void;
}

const DiagramLine = ({
  userHasPermissions,
  startItem,
  controlContainer,
  endItem,
  direction = DiagramLineDirection.LeftToRight,
  onClick,
}: DiagramLineProps) => {
  const startItemParent = useRef(startItem.offsetParent as HTMLElement);
  const endItemParent = useRef(endItem.offsetParent as HTMLElement);

  const calculateSelectorLine = (): DiagramLineSettings => {
    if (direction === DiagramLineDirection.LeftToRight) {
      return {
        x1: startItem.offsetLeft + startItem.clientWidth,
        y1: startItem.offsetTop + startItem.clientHeight / 2 + 30,
        x2: controlContainer.offsetLeft + controlContainer.clientWidth,
        y2: startItem.offsetTop + startItem.clientHeight / 2 + 30,
      };
    } else {
      return {
        x1: startItem.offsetLeft + startItemParent.current.offsetLeft,
        y1: startItem.offsetTop + startItem.clientWidth / 2 - 5,
        x2: controlContainer.offsetLeft,
        y2: startItem.offsetTop + startItem.clientWidth / 2 - 5,
      };
    }
  };

  const calculateConnectorLine = (): DiagramLineSettings => {
    if (direction === DiagramLineDirection.LeftToRight) {
      return {
        x1: controlContainer.offsetLeft + controlContainer.clientWidth,
        y1: startItem.offsetTop + startItem.clientHeight / 2 + 30,
        x2: endItem.offsetLeft + endItemParent.current.offsetLeft,
        y2: endItem.offsetTop + endItem.clientHeight / 2,
      };
    } else {
      return {
        x1: controlContainer.offsetLeft,
        y1: startItem.offsetTop + startItem.clientWidth / 2 - 5,
        x2: endItem.offsetLeft + endItem.clientWidth + endItemParent.current.offsetLeft + 5,
        y2: endItem.offsetTop + endItem.clientHeight / 2,
      };
    }
  };

  return (
    <g className="diagram-line">
      <line {...calculateSelectorLine()} />
      <line {...calculateConnectorLine()} />
      {userHasPermissions && (
        <>
          <AddPlus
            x={controlContainer.offsetLeft + controlContainer.clientWidth / 2}
            y={startItem.offsetTop + startItem.clientHeight / 2 + 30}
          />
          <line {...calculateSelectorLine()} className="selector-line" onClick={onClick} />
        </>
      )}
    </g>
  );
};

export default DiagramLine;
