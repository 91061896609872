import './add-plus.component.css';

export interface AddPlusProps {
  x: number;
  y: number;
}

const AddPlus = ({ x, y }: AddPlusProps) => {
  return (
    <g id="add-plus">
      <circle cx={x} cy={y} r="10" fill="white" className="plus-style" />
      <line x1={x} y1={y - 7} x2={x} y2={y + 7} className="plus-style" />
      <line x1={x - 7} y1={y} x2={x + 7} y2={y} className="plus-style" />
    </g>
  );
};

export default AddPlus;
