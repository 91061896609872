import { combineReducers } from 'redux';
import diagramReducer from '../diagram/diagramReducer';
import filterReducer from '../filter/filterReducer';
import userReducer from '../user/userReducer';

const reducers = combineReducers({
  diagram: diagramReducer,
  filter: filterReducer,
  user: userReducer,
});

export default reducers;
