import { DynamicFormSettingsWrapper } from './form-data-types';
import { ServiceBase } from './service-base';

export class FormService extends ServiceBase {
  constructor(env: string) {
    super(env);
  }

  public fetchFormInfoByFormId(formId: number) {
    const requestUrl = this.createEndpointUrl(`forms/simple/${formId}`);
    return this.performApiRequest<DynamicFormSettingsWrapper>(requestUrl, 'GET');
  }
}
