import { Container, makeStyles, Typography } from '@material-ui/core';
import { QueryValidation } from '../../guards/ParamGuard';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import ErrorIcon from '../../../assets/images/error.png';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  icon: {
    width: '20%',
    [theme.breakpoints.down('xs')]: {
      width: '40%',
    },
  },

  infoHeader: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem ',
    },
  },

  infoText: {
    marginTop: '1rem',
    width: '50%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export interface GeneralErrorContainerProps {
  errorMessage: string;
  errorDescription: string;
  loading?: boolean;
  validation?: QueryValidation;
}

export default function GeneralErrorContainer({
  errorMessage,
  errorDescription,
  validation,
  loading,
}: GeneralErrorContainerProps) {
  const classes = useStyles();

  if (loading) {
    return null;
  }

  return validation && Object.keys(validation).length > 0 ? (
    <Container component="main" maxWidth="lg" className={classes.container}>
      <img src={ErrorIcon} alt="missing_info" className={classes.icon} />
      {validation && validation.missingParams && validation.missingParams.length > 0 ? (
        <Typography variant="h3" component="h3" className={classes.infoHeader}>
          {validation.missingParams.map((missingParam) => `'${missingParam}'`).join(' , ')}{' '}
          {validation.missingParams.length > 1 ? 'parameters' : 'parameter'} not found!
        </Typography>
      ) : validation.invalidParams && validation.invalidParams.length > 0 ? (
        <Typography variant="h3" component="h3" className={classes.infoHeader}>
          {validation.invalidParams.map((invalidParam) => `'${invalidParam}'`).join(' , ')}{' '}
          {validation.missingParams.length > 1 ? 'parameters are ' : 'parameter is '} invalid!
        </Typography>
      ) : (
        <Typography variant="h3" component="h3" className={classes.infoHeader}>
          Parameters not found!
        </Typography>
      )}
      <Typography variant="body1" component="p" className={classes.infoText}>
        Looks like the neccessary required parameters were not passed to the URL or were not valid. Make sure to have
        the right navigation link when trying to access this page.
      </Typography>
      {validation && validation.initialUrl && validation.initialUrl.length > 0 && (
        <Typography variant="body1" component="p" className={classes.infoText} style={{ whiteSpace: 'pre-wrap' }}>
          The navigation link you provided is: <br />
          <i title={validation.initialUrl}>
            {validation.initialUrl.length > 100 ? validation.initialUrl.slice(0, 100) + '...' : validation.initialUrl}
          </i>
        </Typography>
      )}
      <Typography variant="body1" component="p" className={classes.infoText}>
        The navigation link should have the following structure:{' '}
        <i>
          https://bowtie-base-url.com?schemaName=
          <span style={{ color: '#1976d2' }}>[validSchemaName]</span>&records=
          <span style={{ color: '#1976d2' }}>[validRecordId (Numeric)]</span>
        </i>
      </Typography>
    </Container>
  ) : (
    <Container component="main" maxWidth="lg" className={classes.container}>
      <img src={ErrorIcon} alt="missing_info" className={classes.icon} />
      {errorMessage && errorMessage.length > 0 && (
        <Typography variant="h3" component="h3" className={classes.infoHeader}>
          {errorMessage}
        </Typography>
      )}
      {errorDescription && errorDescription.length > 0 && (
        <Typography variant="body1" component="p" className={classes.infoText}>
          {errorDescription}
        </Typography>
      )}
    </Container>
  );
}
