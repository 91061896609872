import { AddonsWrapper } from './addon-data-types';
import { ServiceBase } from './service-base';

export class AddonService extends ServiceBase {
  constructor(env: string) {
    super(env);
  }

  public fetchAddonsByFormId(formId: number) {
    const requestUrl = this.createEndpointUrl('addon/list', { formId });
    return this.performApiRequest<AddonsWrapper>(requestUrl, 'GET');
  }
}
