import {
  DIAGRAM_FETCH_RECORD_START,
  DIAGRAM_FETCH_RECORD_SUCCESS,
  DIAGRAM_FETCH_RECORD_FAIL,
  DIAGRAM_ADD_CAUSE_FAIL,
  DIAGRAM_ADD_CAUSE_START,
  DIAGRAM_ADD_CAUSE_SUCCESS,
  DIAGRAM_ADD_CONSEQUENCE_START,
  DIAGRAM_ADD_CONSEQUENCE_FAIL,
  DIAGRAM_ADD_CONSEQUENCE_SUCCESS,
  DIAGRAM_ADD_CAUSE_INPUT,
  DIAGRAM_ADD_CONSEQUENCE_INPUT,
  DIAGRAM_ADD_MITIGATING_CONTROLS_FAIL,
  DIAGRAM_ADD_MITIGATING_CONTROLS_INPUT,
  DIAGRAM_ADD_MITIGATING_CONTROLS_START,
  DIAGRAM_ADD_MITIGATING_CONTROLS_SUCCESS,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_FAIL,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_INPUT,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_START,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_SUCCESS,
  DIAGRAM_CLEAR_STATE,
  DIAGRAM_ADD_RISK_SCENARIO_FAIL,
  DIAGRAM_ADD_RISK_SCENARIO_START,
  DIAGRAM_ADD_RISK_SCENARIO_SUCCESS,
  DIAGRAM_ADD_HAZARD_FAIL,
  DIAGRAM_ADD_HAZARD_START,
  DIAGRAM_ADD_HAZARD_SUCCESS,
  DIAGRAM_REMOVE_CAUSE,
  DIAGRAM_REMOVE_CONSEQUENCE,
  DIAGRAM_REMOVE_PREVENTATIVE_CONTROL,
  DIAGRAM_REMOVE_MITIGATING_CONTROL,
  DIAGRAM_UNLINK_CONTROL_SUCCESS,
  DIAGRAM_UNLINK_CONTROL_START,
  DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL,
  DIAGRAM_FETCH_RECORDS_BY_FORM_START,
  DIAGRAM_FETCH_RECORDS_BY_FORM_SUCCESS,
  DIAGRAM_RESET_FORM_RECORDS,
  DIAGRAM_FETCH_SCENARIO_RECORD_START,
  DIAGRAM_FETCH_SCENARIO_RECORD_FAIL,
  DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS,
  DIAGRAM_FETCH_SCENARIO_RECORD_MORE,
  DIAGRAM_UPDATE_BOWTIE_SCENARIO,
  DIAGRAM_CONFIGURATION_MAIN_SUCCESS,
  DIAGRAM_CONFIGURATION_MAIN_FAIL,
  DIAGRAM_QUEUE_ADD,
  DIAGRAM_QUEUE_STATUS,
  DIAGRAM_QUEUE_UPDATE,
  DIAGRAM_QUEUE_FAIL,
  DIAGRAM_QUEUE_ADD_FAILED,
  DIAGRAM_QUEUE_UPDATE_FAILED,
  DIAGRAM_LAYOUT,
} from './diagramTypes';

const INITIAL_STATE = {
  errorMessage: null,
  loading: true,
  loadingMessage: null,
  bowtieData: {
    hazard: null,
    scenario: null,
    causes: [],
    consequences: [],
  },
  addBowtieData: {
    loading: false,
    errorMessage: null,
  },
  removeBowtieData: {
    loading: false,
    errorMessage: null,
  },
  formRecords: {
    loading: false,
    errorMessage: null,
    items: [],
  },
  riskScenarioRecords: {
    records: [],
    loading: true,
    errorMessage: null,
    isFetching: false,
    isLastPage: false,
  },
  configuration: {
    main: null,
  },
  queue: {
    tasks: [],
    failed: [],
    isQueuing: false,
    isRemovingQueue: false,
  },
  diagramLayout: {
    isWidth: false,
    isHeight: false,
  },
};

export default function recordReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case DIAGRAM_FETCH_RECORD_START: {
      return {
        ...state,
        loading: true,
        loadingMessage: payload,
        errorMessage: null,
      };
    }

    case DIAGRAM_CLEAR_STATE: {
      return INITIAL_STATE;
    }

    case DIAGRAM_FETCH_RECORD_SUCCESS: {
      return {
        ...state,
        bowtieData: { ...payload },
        loading: false,
        loadingMessage: null,
        errorMessage: null,
      };
    }

    case DIAGRAM_FETCH_RECORD_FAIL: {
      return {
        ...state,
        bowtieData: null,
        loading: false,
        loadingMessage: null,
        errorMessage: payload,
      };
    }

    case DIAGRAM_ADD_CAUSE_INPUT: {
      const placeholderInput = state.bowtieData?.causes?.find((cause) => !cause.value);

      if (placeholderInput) {
        return state;
      }

      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          causes: [...(state.bowtieData.causes || []), payload],
        },
      };
    }

    case DIAGRAM_ADD_CAUSE_START: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: true,
          errorMessage: null,
        },
      };
    }

    case DIAGRAM_ADD_CAUSE_FAIL: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: payload,
        },
      };
    }

    case DIAGRAM_ADD_CAUSE_SUCCESS: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          causes: [...state.bowtieData.causes.filter((cause) => cause.id !== payload.id), payload],
        },
      };
    }

    case DIAGRAM_REMOVE_CAUSE: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          causes: [...state.bowtieData.causes.filter((cause) => cause.id !== payload)],
        },
      };
    }

    case DIAGRAM_REMOVE_CONSEQUENCE: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          consequences: [...state.bowtieData.consequences.filter((consequence) => consequence.id !== payload)],
        },
      };
    }

    case DIAGRAM_ADD_CONSEQUENCE_INPUT: {
      const placeholderInput = state.bowtieData?.consequences?.find((consequence) => !consequence.value);

      if (placeholderInput) {
        return state;
      }
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          consequences: [...(state.bowtieData.consequences || []), payload],
        },
      };
    }

    case DIAGRAM_ADD_CONSEQUENCE_START: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: true,
          errorMessage: null,
        },
      };
    }

    case DIAGRAM_ADD_CONSEQUENCE_FAIL: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: payload,
        },
      };
    }

    case DIAGRAM_ADD_CONSEQUENCE_SUCCESS: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          consequences: [
            ...state.bowtieData.consequences.filter((consequence) => consequence.id !== payload.id),
            payload,
          ],
        },
      };
    }

    case DIAGRAM_ADD_MITIGATING_CONTROLS_INPUT: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          consequences: [
            ...state.bowtieData.consequences.map((consequence, index) => {
              if (payload.consequenceId === index) {
                const placeholderInput = consequence.mitigatingControls.find(
                  (mitigatingControl) => !mitigatingControl.value
                );
                if (placeholderInput) {
                  return consequence;
                }

                return {
                  ...consequence,
                  mitigatingControls: [...consequence.mitigatingControls, payload],
                };
              }

              return consequence;
            }),
          ],
        },
      };
    }

    case DIAGRAM_ADD_MITIGATING_CONTROLS_START: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: true,
          errorMessage: null,
        },
      };
    }

    case DIAGRAM_ADD_MITIGATING_CONTROLS_FAIL: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: payload,
        },
      };
    }

    case DIAGRAM_ADD_MITIGATING_CONTROLS_SUCCESS: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          scenarioRecord: payload.riskScenarioRecord,
          consequences: [
            ...state.bowtieData.consequences.map((consequence) => {
              if (consequence.uuid === payload.mcPayload.parentId) {
                return {
                  ...consequence,
                  mitigatingControls: [
                    ...consequence.mitigatingControls.filter((mitigatingControl) => mitigatingControl.value),
                    { ...payload.mcPayload, global: payload.global },
                  ],
                };
              }
              return consequence;
            }),
          ],
        },
      };
    }

    case DIAGRAM_REMOVE_PREVENTATIVE_CONTROL: {
      return {
        ...state,
        bowtieData: {
          ...state.bowtieData,
          causes: [
            ...state.bowtieData.causes.map((cause) => {
              if (payload.parentId === cause.uuid) {
                return {
                  ...cause,
                  preventativeControls: cause.preventativeControls.filter(
                    (preventativeControl) => preventativeControl.id !== payload.id
                  ),
                };
              }

              return cause;
            }),
          ],
        },
      };
    }

    case DIAGRAM_REMOVE_MITIGATING_CONTROL: {
      return {
        ...state,
        bowtieData: {
          ...state.bowtieData,
          consequences: [
            ...state.bowtieData.consequences.map((consequence) => {
              if (payload.parentId === consequence.uuid) {
                return {
                  ...consequence,
                  mitigatingControls: consequence.mitigatingControls.filter(
                    (mitigatingControl) => mitigatingControl.id !== payload.id
                  ),
                };
              }

              return consequence;
            }),
          ],
        },
      };
    }

    case DIAGRAM_ADD_RISK_SCENARIO_START: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: true,
          errorMessage: null,
        },
      };
    }

    case DIAGRAM_ADD_RISK_SCENARIO_FAIL: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: payload,
        },
      };
    }

    case DIAGRAM_ADD_RISK_SCENARIO_SUCCESS: {
      const storedRecords = JSON.parse(localStorage.getItem('bowtieRecords'));

      const updatedFormRecords = storedRecords?.filter((item) => item.value);

      updatedFormRecords.unshift(payload.recordItem);

      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          ...payload,
          records: updatedFormRecords,
        },
      };
    }

    case DIAGRAM_ADD_HAZARD_START: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: true,
          errorMessage: null,
        },
      };
    }

    case DIAGRAM_ADD_HAZARD_FAIL: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: payload,
        },
      };
    }

    case DIAGRAM_ADD_HAZARD_SUCCESS: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          hazard: payload,
        },
      };
    }

    case DIAGRAM_ADD_PREVENTATIVE_CONTROLS_INPUT: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          causes: [
            ...state.bowtieData.causes.map((cause, index) => {
              if (payload.causeId === index) {
                const placeholderInput = cause.preventativeControls.find(
                  (preventativeControl) => !preventativeControl.value
                );
                if (placeholderInput) {
                  return cause;
                }

                return {
                  ...cause,
                  preventativeControls: [...cause.preventativeControls, payload],
                };
              }

              return cause;
            }),
          ],
        },
      };
    }

    case DIAGRAM_ADD_PREVENTATIVE_CONTROLS_START: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: true,
          errorMessage: null,
        },
      };
    }

    case DIAGRAM_ADD_PREVENTATIVE_CONTROLS_FAIL: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: payload,
        },
      };
    }

    case DIAGRAM_ADD_PREVENTATIVE_CONTROLS_SUCCESS: {
      return {
        ...state,
        addBowtieData: {
          ...state.addBowtieData,
          loading: false,
          errorMessage: null,
        },
        bowtieData: {
          ...state.bowtieData,
          scenarioRecord: payload.riskScenarioRecord,
          causes: [
            ...state.bowtieData.causes.map((cause) => {
              if (cause.uuid === payload.pcPayload?.parentId) {
                return {
                  ...cause,
                  preventativeControls: [
                    ...cause.preventativeControls.filter((preventativeControl) => preventativeControl.value),
                    { ...payload.pcPayload, global: payload.global },
                  ],
                };
              }
              return cause;
            }),
          ],
        },
      };
    }

    case DIAGRAM_UNLINK_CONTROL_START: {
      return {
        ...state,
        bowtieData: {
          ...state.bowtieData,
          ...payload,
        },
      };
    }

    case DIAGRAM_UNLINK_CONTROL_SUCCESS: {
      return {
        ...state,
        bowtieData: {
          ...state.bowtieData,
          ...payload,
        },
      };
    }

    case DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL: {
      return {
        ...state,
        formRecords: {
          ...state.formRecords,
          loading: false,
          errorMessage: payload,
        },
      };
    }

    case DIAGRAM_FETCH_RECORDS_BY_FORM_START: {
      return {
        ...state,
        formRecords: {
          ...state.formRecords,
          loading: true,
          errorMessage: null,
          items: [],
        },
      };
    }

    case DIAGRAM_FETCH_RECORDS_BY_FORM_SUCCESS: {
      return {
        ...state,
        formRecords: {
          ...state.formRecords,
          loading: false,
          errorMessage: null,
          items: payload,
        },
      };
    }

    case DIAGRAM_RESET_FORM_RECORDS: {
      return {
        ...state,
        formRecords: INITIAL_STATE.formRecords,
      };
    }

    case DIAGRAM_FETCH_SCENARIO_RECORD_START: {
      return {
        ...state,
        riskScenarioRecords: {
          records: [],
          loading: true,
          errorMessage: null,
        },
      };
    }

    case DIAGRAM_FETCH_SCENARIO_RECORD_MORE: {
      const { records, isFetching, isLastPage } = payload;

      return {
        ...state,
        riskScenarioRecords: {
          records,
          loading: false,
          isFetching,
          isLastPage,
          errorMessage: null,
        },
      };
    }

    case DIAGRAM_FETCH_SCENARIO_RECORD_SUCCESS: {
      const { records, isLastPage } = payload;

      return {
        ...state,
        riskScenarioRecords: {
          records,
          loading: false,
          errorMessage: null,
          isFetching: false,
          isLastPage,
        },
      };
    }

    case DIAGRAM_UPDATE_BOWTIE_SCENARIO: {
      return {
        ...state,
        bowtieData: {
          ...state.bowtieData,
          scenario: payload,
        },
      };
    }

    case DIAGRAM_FETCH_SCENARIO_RECORD_FAIL: {
      return {
        ...state,
        loading: false,
        riskScenarioRecords: {
          records: [],
          loading: false,
          errorMessage: payload,
        },
      };
    }

    case DIAGRAM_CONFIGURATION_MAIN_SUCCESS: {
      return {
        ...state,
        configuration: {
          main: payload,
        },
      };
    }

    case DIAGRAM_CONFIGURATION_MAIN_FAIL: {
      return {
        ...state,
        configuration: null,
      };
    }

    case DIAGRAM_QUEUE_ADD: {
      return {
        ...state,
        queue: {
          ...state.queue,
          tasks: [...state.queue?.tasks, payload],
        },
      };
    }

    case DIAGRAM_QUEUE_ADD_FAILED: {
      return {
        ...state,
        queue: {
          ...state.queue,
          failed: [...state.queue?.failed, payload],
        },
      };
    }

    case DIAGRAM_QUEUE_STATUS: {
      return {
        ...state,
        queue: {
          ...state.queue,
          isQueuing: payload,
        },
      };
    }

    case DIAGRAM_QUEUE_FAIL: {
      return {
        ...state,
        queue: {
          ...state.queue,
          isRemovingQueue: payload,
        },
      };
    }

    case DIAGRAM_QUEUE_UPDATE: {
      return {
        ...state,
        queue: {
          ...state.queue,
          tasks: payload,
          isQueuing: false,
        },
      };
    }

    case DIAGRAM_QUEUE_UPDATE_FAILED: {
      return {
        ...state,
        queue: {
          ...state.queue,
          failed: payload,
          isRemovingQueue: false,
        },
      };
    }

    case DIAGRAM_LAYOUT: {
      const { isWidth } = payload;

      return {
        ...state,
        diagramLayout: {
          ...state.diagramLayout,
          isWidth,
        },
      };
    }

    default:
      return state;
  }
}
