export const environment = {
    textLineWidth: 16,
    apiUrl: '',
    token: '',
    positionLeft: -180, // positionLeft side
    positionRight: +180, // positionRight side
    PMCPosition: 35, //Distance Causes-Preventative, Consequences-Mitigating
    distanceVertically: 132, // > 2*heightRectagular + 10px
    primePosition: window.innerHeight / 2, // First position Main Horizontally
    positionFirst: 140,
    colorCauses: '#aba59c',
    textColorCauses: 'white',
    bgColorCauses: '#fff6e9',
    colorConsequences: '#aba59c',
    textColorConsequences: 'white',
    bgColorConsequences: '#fff6e9',
    colorMitigating: '#aba59c',
    textColorMitigating: 'white',
    bgColorMitigating: '#fff6e9',
    colorPreventative: '#aba59c',
    textColorPreventative: 'white',
    bgColorPreventative: '#fff6e9',
}

export const riskScenarioPageSize = 50;

export const palettes = {
    darkBlue: {
        primary: "#00398f",
    },
    lightBlue: {
        primary: "#e6eefa",
        rgb: {
            4: "rgba(230, 238, 250, 0.4)"
        }
    },
    lightGray: {
        primary: "#ababab",
        background: "#e1e1e0"
    },
    gray: {
        primary: '#727275'
    },
    criticalControl: {
        primary: '#99baeb',
        rgb: {
            8: "rgb(153, 186, 235, 0.8)",
            96: "rgb(153, 186, 235, 96)",
            5: "rgb(153, 186, 235, 0.5)"
        }
    },
    controlIssue: {
        primary: '#ff7b7b',
        rgb: {
            5: "rgba(240, 22, 22, 0.5)"
        }
    },
    text: {
        white: '#fff',
        primary: 'rgb(51, 51, 51)',
        secondary: '#e1e1e0'
    },
    lightWhite: {
        primary: 'rgb(240, 240, 240)'
    }
}

export const hazardAndRiskDefaultStyle = {
    color: "#333",
    width: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontSize: "12px",
    textAlign: "center",
    borderRadius: "2px",
    zIndex: "3000",
    position: "relative",
    outline: "none",
    height: "96px",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderColor: palettes.criticalControl.primary,
    backgroundColor: "rgb(240, 240, 240)",
    padding: "28px",
}
