import './MultiLineContainer.css';
import PropTypes from 'prop-types';
import Rectangle, { LINE_DIRECTION } from '../rectangle/Rectangle';
import filterSelectors from '../../../../modules/filter/filterSelectors';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../views/draftContainer/shared/components/Button';
import { useState, useEffect, useRef } from 'react';
import diagramActions from '../../../../modules/diagram/diagramActions';
import TextArea from '../../../../views/draftContainer/shared/components/TextArea';
import RecordServiceJS from '../../../../modules/diagram/diagramService';
import diagramSelectors from '../../../../modules/diagram/diagramSelectors';
import userSelectors from '../../../../modules/user/userSelectors';
import { palettes } from '../../../../environment/environment';
import LoadingControls from '../../../Items/LoadingComponent/LoadingControls';
import { savingStyle, defaultStyle } from '../../../../helpers/config';

const recordService = new RecordServiceJS('test');

const MULTI_LINE_CONTAINER_MODE = {
  SINGLE: 'single',
  MULTI: 'multi',
};

const CONTAINER_ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
};

const criticalRectangularStyling = {
  backgroundColor: palettes.criticalControl.primary,
  borderColor: palettes.criticalControl.primary,
};

const nonEffectiveRectangularStyling = {
  backgroundColor: palettes.controlIssue.primary,
  borderColor: palettes.controlIssue.primary,
  color: palettes.text.white,
};

const criticalAndNonEffectiveRectangularStyling = {
  backgroundColor: palettes.lightBlue.primary,
  borderColor: palettes.lightBlue.primary,
};

const defaultRectangularStyling = {
  backgroundColor: palettes.lightBlue.primary,
  borderColor: palettes.lightBlue.primary,
};

function getRectangularFilterStyling(
  line,
  criticalFilter,
  nonEffectiveFilter,
  criticalFilterValues,
  nonEffectiveFilterValues
) {
  if (line.effectiveOrNotEffective && line.effectiveOrNotEffective.value === nonEffectiveFilterValues.falsy) {
    return nonEffectiveRectangularStyling;
  } else {
    if (criticalFilter && nonEffectiveFilter && line.criticalOrNonCritical === criticalFilterValues.truthy) {
      return criticalRectangularStyling;
    } else if (criticalFilter && line.criticalOrNonCritical === criticalFilterValues.falsy) {
      return criticalAndNonEffectiveRectangularStyling;
    } else {
      return defaultRectangularStyling;
    }
  }
}

function SingleElementLine(props) {
  const dispatch = useDispatch();
  const { line, index, id } = props;
  const addInput = useRef();
  const [disabled, setDisabled] = useState(false);
  const [editElement, setEditElement] = useState(false);
  const bowtieData = useSelector(diagramSelectors.selectBowtieData);
  const userWithPermissions = useSelector(userSelectors.selectUser);

  const [rectangularStyle, setRectangularStyle] = useState({
    color: palettes.text.primary,
    borderWidth: '1px',
    width: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '12px',
    textAlign: 'center',
    borderStyle: 'dashed',
    borderRadius: '0.6rem',
    zIndex: '3000',
    position: 'relative',
    outline: 'none',
    height: '50px',
    padding: '10px',
    borderColor: palettes.criticalControl.primary,
    backgroundColor: palettes.lightWhite.primary,
  });

  useEffect(() => {
    if (addInput && addInput.current && addInput.current.focus) {
      addInput.current.focus();
    }
  }, [addInput, addInput.current]);

  const addElement = (payload) => {
    if (id === 'causes') {
      dispatch(diagramActions.doAddCause(payload));
    } else {
      dispatch(diagramActions.doAddConsequence(payload));
    }
  };

  const removeElement = (payload) => {
    if (id === 'causes') {
      dispatch(diagramActions.doRemoveCause(payload));
    } else {
      dispatch(diagramActions.doRemoveConsequence(payload));
    }
  };

  const onAddElement = async () => {
    if (addInput.current && addInput.current.value) {
      setDisabled(true);
      setRectangularStyle({
        ...rectangularStyle,
        ...savingStyle,
      });

      if (addInput.current.value === line.value) {
        setEditElement(false);
        setDisabled(false);
        setRectangularStyle({
          ...rectangularStyle,
          ...defaultStyle,
        });
      } else if (editElement) {
        if (id === 'causes') {
          await recordService.updateCause(bowtieData, line, addInput.current.value);
        } else {
          await recordService.updateConsequence(bowtieData, line, addInput.current.value);
        }

        line.value = addInput.current.value;
        setEditElement(false);
        setDisabled(false);
        setRectangularStyle({
          ...rectangularStyle,
          ...defaultStyle,
        });
      } else {
        setEditElement(false);
        addElement({
          id: line.id,
          value: addInput.current.value,
          lineIndex: index,
        });
        setRectangularStyle({
          ...rectangularStyle,
          ...savingStyle,
        });
        setDisabled(true);
      }
    } else if (!editElement) {
      setDisabled(false);
      setEditElement(false);
      removeElement(line.id);
    } else {
      setDisabled(false);
      setEditElement(false);
    }
  };

  const handleOnKeyDown = async (event) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !event.shiftKey) {
      event.preventDefault();
      addInput.current.blur();
    }
  };

  const handleOnRectangularEdit = () => {
    if (
      userWithPermissions &&
      userWithPermissions?.permissions &&
      userWithPermissions?.permissions?.editRecord &&
      userWithPermissions?.permissions?.editRecord?.[id]
    ) {
      setEditElement(true);
      setDisabled(false);
      setRectangularStyle({
        ...rectangularStyle,
        ...defaultStyle,
      });
    }
  };

  if (!line.value || editElement) {
    return (
      <div>
        <textarea
          onKeyDown={handleOnKeyDown}
          key={index}
          defaultValue={line.value ? line.value : ''}
          id={`${id + '_' + (index + 1)}`}
          ref={addInput}
          disabled={disabled}
          onBlur={onAddElement}
          style={{
            ...rectangularStyle,
            marginBottom: '8rem',
            resize: 'none',
            textOpacity: '20%',
          }}
          rows={2}
        />
        {disabled && (
          <div className="box-loading-causes">
            <LoadingControls />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="single-element-line-container" key={index}>
      <Rectangle
        link={line.linkUrl}
        id={`${id + '_' + (index + 1)}`}
        text={line.value}
        color="#ffffff"
        backgroundColor={palettes.lightGray.primary}
        borderColor={palettes.lightGray.primary}
        form={
          id === 'causes'
            ? bowtieData?.bowtieConfiguration?.forms?.causes?.form
            : bowtieData?.bowtieConfiguration?.forms?.consequences?.form
        }
        borderWidth="2px"
        width="120px"
        fontSize="12px"
        status={line.status}
        containerBackgroundColor={'rgb(255, 246, 233)'}
        click={handleOnRectangularEdit}
      />
    </div>
  );
}

function MultiElementLine(props) {
  const [elementToEdit, setElementToEdit] = useState(null);

  const criticalFilterValues = useSelector(filterSelectors.selectCriticalFilterValues);

  const nonEffectiveFilterValues = useSelector(filterSelectors.selectNonEffectiveFilterValues);
  const criticalFilter = useSelector(filterSelectors.selectCriticalFilter);
  const nonEffectiveFilter = useSelector(filterSelectors.selectNonEffectiveFilter);

  const userWithPermissions = useSelector(userSelectors.selectUser);

  const bowtieData = useSelector(diagramSelectors.selectBowtieData);

  const { multiLines, alignment, id, index, form } = props;

  const lines = multiLines.childControls;

  const dispatch = useDispatch();

  const addElement = (payload) => {
    const obj = {
      ...payload,
      parentId: multiLines.parentId,
    };

    dispatch(
      diagramActions.doAddInQueue({
        payload: obj,
        type: id,
      })
    );
  };

  const removeElement = (payload) => {
    if (id === 'preventative_control') {
      dispatch(diagramActions.doRemovePreventativeControl(payload));
    } else {
      dispatch(diagramActions.doRemoveMitigatingControl(payload));
    }
  };

  return (
    <div className="multi-element-line-container" key={index}>
      {lines && lines.length <= 2 ? (
        <div
          className="multi-element-first-line"
          style={{
            height: '178px',
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'space-between',
            paddingBottom: '30px',
          }}
        >
          <div
            className="second-line-container"
            style={{
              display: 'flex',
              flexDirection: alignment === CONTAINER_ALIGNMENT.LEFT ? 'row' : 'row-reverse',
            }}
          >
            {lines &&
              lines.map((line, i) => {
                if (line.value === null || elementToEdit === line.id) {
                  return (
                    <TextArea
                      key={`${line.id}_${i}`}
                      index={i}
                      id={id}
                      elementId={line.id}
                      addElement={addElement}
                      removeElement={removeElement}
                      lineDirection={LINE_DIRECTION.TOP}
                      parentId={multiLines.parentId}
                      defaultValue={line.value}
                      line={line}
                      setElementToEdit={setElementToEdit}
                      form={form}
                    />
                  );
                }
                if (i + 1 === lines.length) {
                  return (
                    <div className="line-rectangular-container" key={i}>
                      <Rectangle
                        color="#333"
                        link={line.linkUrl}
                        text={line.value}
                        status={line.status}
                        borderWidth="2px"
                        width="120px"
                        fontSize="12px"
                        id={`final_${id}_${index + 1}`}
                        hasLine={true}
                        lineColor={palettes.criticalControl.primary}
                        lineDirection={LINE_DIRECTION.TOP}
                        containerBackgroundColor={'rgb(240, 240, 240)'}
                        rawElement={{ ...line, parentId: multiLines.parentId }}
                        {...getRectangularFilterStyling(
                          line,
                          criticalFilter,
                          nonEffectiveFilter,
                          criticalFilterValues,
                          nonEffectiveFilterValues
                        )}
                        form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                        click={() => {
                          if (
                            userWithPermissions &&
                            userWithPermissions.permissions &&
                            userWithPermissions.permissions.editRecord &&
                            userWithPermissions.permissions.editRecord.controls
                          ) {
                            setElementToEdit(line.id);
                          }
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <div className="line-rectangular-container" id={line.value} key={i}>
                    <Rectangle
                      color="#333"
                      link={line.linkUrl}
                      text={line.value}
                      status={line.status}
                      borderWidth="2px"
                      width="120px"
                      fontSize="12px"
                      hasLine={true}
                      lineColor={palettes.criticalControl.primary}
                      lineDirection={LINE_DIRECTION.TOP}
                      containerBackgroundColor={'rgb(240, 240, 240)'}
                      rawElement={{ ...line, parentId: multiLines.parentId }}
                      form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                      {...getRectangularFilterStyling(
                        line,
                        criticalFilter,
                        nonEffectiveFilter,
                        criticalFilterValues,
                        nonEffectiveFilterValues
                      )}
                      click={() => {
                        if (
                          userWithPermissions &&
                          userWithPermissions.permissions &&
                          userWithPermissions.permissions.editRecord &&
                          userWithPermissions.permissions.editRecord.controls
                        ) {
                          setElementToEdit(line.id);
                        }
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div
          style={{
            height: '178px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: '30px',
          }}
          key={index}
        >
          <div
            className="multi-element-first-line"
            style={{
              display: 'flex',
              flexDirection: alignment === CONTAINER_ALIGNMENT.LEFT ? 'row' : 'row-reverse',
            }}
          >
            {lines.map((line, i) => {
              if (i % 2 === 0) {
                if (line.value === null || elementToEdit === line.id) {
                  return (
                    <TextArea
                      key={`${line.id}_${i}`}
                      index={i}
                      id={id}
                      elementId={line.id}
                      addElement={addElement}
                      removeElement={removeElement}
                      lineDirection={LINE_DIRECTION.BOTTOM}
                      parentId={multiLines.parentId}
                      defaultValue={line.value}
                      line={line}
                      setElementToEdit={setElementToEdit}
                      form={form}
                    />
                  );
                }
                if (i + 1 === lines.length) {
                  return (
                    <div className="line-rectangular-container" key={i}>
                      <Rectangle
                        color="#333"
                        link={line.linkUrl}
                        text={line.value}
                        borderWidth="2px"
                        status={line.status}
                        width="120px"
                        fontSize="12px"
                        id={`final_${id}_${index + 1}`}
                        hasLine={true}
                        lineColor={palettes.criticalControl.primary}
                        lineDirection={LINE_DIRECTION.BOTTOM}
                        editable={line.value === null ? true : false}
                        containerBackgroundColor={'rgb(240, 240, 240)'}
                        form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                        rawElement={{ ...line, parentId: multiLines.parentId }}
                        {...getRectangularFilterStyling(
                          line,
                          criticalFilter,
                          nonEffectiveFilter,
                          criticalFilterValues,
                          nonEffectiveFilterValues
                        )}
                        click={() => {
                          if (
                            userWithPermissions &&
                            userWithPermissions.permissions &&
                            userWithPermissions.permissions.editRecord &&
                            userWithPermissions.permissions.editRecord.controls
                          ) {
                            setElementToEdit(line.id);
                          }
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <div className="line-rectangular-container" key={i} id={line.value}>
                    <Rectangle
                      color="#333"
                      link={line.linkUrl}
                      id={line.value}
                      text={line.value}
                      status={line.status}
                      borderWidth="2px"
                      width="120px"
                      fontSize="12px"
                      hasLine={true}
                      lineColor={palettes.criticalControl.primary}
                      lineDirection={LINE_DIRECTION.BOTTOM}
                      containerBackgroundColor={'rgb(240, 240, 240)'}
                      form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                      rawElement={{ ...line, parentId: multiLines.parentId }}
                      {...getRectangularFilterStyling(
                        line,
                        criticalFilter,
                        nonEffectiveFilter,
                        criticalFilterValues,
                        nonEffectiveFilterValues
                      )}
                      click={() => {
                        if (
                          userWithPermissions &&
                          userWithPermissions.permissions &&
                          userWithPermissions.permissions.editRecord &&
                          userWithPermissions.permissions.editRecord.controls
                        ) {
                          setElementToEdit(line.id);
                        }
                      }}
                    />{' '}
                  </div>
                );
              }
              return <div className="line-rectangular-container" style={{ width: '120px' }} key={i}></div>;
            })}
          </div>

          <div
            className="multi-element-second-line"
            style={{
              display: 'flex',
              flexDirection: alignment === CONTAINER_ALIGNMENT.LEFT ? 'row' : 'row-reverse',
            }}
          >
            {lines.map((line, i) => {
              if (i % 2 !== 0) {
                if (line.value === null || elementToEdit === line.id) {
                  return (
                    <TextArea
                      key={`${line.id}_${i}`}
                      index={i}
                      id={id}
                      elementId={line.id}
                      addElement={addElement}
                      removeElement={removeElement}
                      lineDirection={LINE_DIRECTION.TOP}
                      parentId={multiLines.parentId}
                      defaultValue={line.value}
                      line={line}
                      setElementToEdit={setElementToEdit}
                      form={form}
                    />
                  );
                }
                if (i + 1 === lines.length) {
                  return (
                    <div className="line-rectangular-container" key={i}>
                      <Rectangle
                        color="#333"
                        link={line.linkUrl}
                        text={line.value}
                        borderWidth="2px"
                        status={line.status}
                        width="120px"
                        fontSize="12px"
                        id={`final_${id}_${index + 1}`}
                        hasLine={true}
                        lineColor={palettes.criticalControl.primary}
                        lineDirection={LINE_DIRECTION.TOP}
                        containerBackgroundColor={'rgb(240, 240, 240)'}
                        form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                        rawElement={{ ...line, parentId: multiLines.parentId }}
                        {...getRectangularFilterStyling(
                          line,
                          criticalFilter,
                          nonEffectiveFilter,
                          criticalFilterValues,
                          nonEffectiveFilterValues
                        )}
                        click={() => {
                          if (
                            userWithPermissions &&
                            userWithPermissions.permissions &&
                            userWithPermissions.permissions.editRecord &&
                            userWithPermissions.permissions.editRecord.controls
                          ) {
                            setElementToEdit(line.id);
                          }
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <div className="line-rectangular-container" key={i} id={line.value}>
                    <Rectangle
                      color="#333"
                      link={line.linkUrl}
                      id={line.value}
                      text={line.value}
                      status={line.status}
                      borderWidth="2px"
                      width="120px"
                      fontSize="12px"
                      hasLine={true}
                      lineColor={palettes.criticalControl.primary}
                      lineDirection={LINE_DIRECTION.TOP}
                      containerBackgroundColor={'rgb(240, 240, 240)'}
                      form={bowtieData?.bowtieConfiguration?.forms?.controls?.form}
                      rawElement={{ ...line, parentId: multiLines.parentId }}
                      {...getRectangularFilterStyling(
                        line,
                        criticalFilter,
                        nonEffectiveFilter,
                        criticalFilterValues,
                        nonEffectiveFilterValues
                      )}
                      click={() => {
                        if (
                          userWithPermissions &&
                          userWithPermissions.permissions &&
                          userWithPermissions.permissions.editRecord &&
                          userWithPermissions.permissions.editRecord.controls
                        ) {
                          setElementToEdit(line.id);
                        }
                      }}
                    />
                  </div>
                );
              }
              return <div className="line-rectangular-container" key={i} style={{ width: '120px' }}></div>;
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default function MultiLineContainer(props) {
  const dispatch = useDispatch();
  const { mode, controlLines, alignment, id, backgroundColor, form } = props;

  const userWithPermissions = useSelector(userSelectors.selectUser);

  function buttonClicked() {
    if (id === 'causes') {
      dispatch(diagramActions.doAddCauseInput());
    } else {
      dispatch(diagramActions.doAddConsequenceInput());
    }
  }

  const renderContainer = () => {
    if (mode === MULTI_LINE_CONTAINER_MODE.SINGLE) {
      return (
        controlLines &&
        controlLines.length > 0 &&
        controlLines.map((line, index) => {
          return (
            <SingleElementLine
              key={`${line.id}_${index}`}
              backgroundColor={backgroundColor}
              line={line}
              index={index}
              id={id}
            />
          );
        })
      );
    }

    if (mode === MULTI_LINE_CONTAINER_MODE.MULTI) {
      return (
        controlLines &&
        controlLines.length > 0 &&
        controlLines.map((line, index) => (
          <MultiElementLine
            key={`${line.id}_${index}`}
            alignment={alignment}
            multiLines={line}
            id={id}
            index={index}
            form={form}
          />
        ))
      );
    }
    return <div />;
  };

  return (
    <div
      className="multi-line-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: mode === MULTI_LINE_CONTAINER_MODE.MULTI && '-3rem',
      }}
    >
      {renderContainer()}
      {mode === MULTI_LINE_CONTAINER_MODE.SINGLE &&
        userWithPermissions &&
        userWithPermissions.permissions &&
        userWithPermissions.permissions.createRecord &&
        userWithPermissions.permissions.createRecord[`${id}`] && (
          <Button text={`Add ${id}`} buttonClicked={buttonClicked} />
        )}
    </div>
  );
}

SingleElementLine.propTypes = {
  line: PropTypes.object,
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
};

MultiElementLine.propTypes = {
  lines: PropTypes.array,
  alignment: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.number,
  backgroundColor: PropTypes.string,
};

MultiLineContainer.propTypes = {
  mode: PropTypes.string,
  lines: PropTypes.array,
  id: PropTypes.string,
  alignment: PropTypes.string,
  backgroundColor: PropTypes.string,
};
