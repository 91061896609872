import { ParsedUrlQueryInput } from 'node:querystring';
import { keys } from './config';
import querystring from 'query-string';

export interface UrlConfig {
  env: string;
  endpoint: string;
}

export function buildUrl(configObject: UrlConfig, queryObject: ParsedUrlQueryInput) {
  const { env, endpoint } = configObject;

  const query = querystring.stringify(queryObject);

  return keys[env] ? `${keys[env].endpointBaseUrl}${endpoint}${query ? '?' + query : ''}` : '';
}
