import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import './ActionModalContent.css';

const actionButtonStyle = {
  background: '#fb911f',
  color: '#ffffff',
  border: 'none',
  outline: 'none',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    color: '#ffffff',
    background: '#fb911f',
  },
  '&:active': {
    border: 'none',
    outline: 'none',
  },
  '&:focus': {
    border: 'none',
    outline: 'none',
  },
};

const cancelButtonStyle = {
  background: '#ffffff',
  color: '#fb911f',
  border: 'none',
  outline: 'none',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    color: '#fb911f',
    background: '#ffffff',
  },
  '&:active': {
    border: 'none',
    outline: 'none',
  },
  '&:focus': {
    border: 'none',
    outline: 'none',
  },
};

const iconStyle = {
  color: '#fb911f',
};

export interface ActionButton {
  onAction: () => void;
  text: string;
}

export interface ActionModalContentProps {
  actionButton: ActionButton;
  onClose: () => void;
  text: string;
}

export default function ActionModalContent({ actionButton, onClose, text }: ActionModalContentProps) {
  return (
    <div className="modal-action-container">
      <div className="modal-action-icon-row">
        <FontAwesomeIcon icon={faClose} style={iconStyle} onClick={onClose} />
      </div>
      <div className="modal-action-text">{text}</div>
      <div className="modal-action-button-row">
        <Button sx={cancelButtonStyle} onClick={onClose}>
          Cancel
        </Button>
        <Button sx={actionButtonStyle} onClick={actionButton.onAction}>
          {actionButton.text}
        </Button>
      </div>
    </div>
  );
}
